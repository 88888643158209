/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "44bits 팟캐스트 116번째 로그에서는 자바 SE API 사용 소송 구글 승, MS 엣지 브라우저 사이드바에 대해서 이야기를 나누었습니다."), "\n", React.createElement(_components.h3, null, "구글과 오라클의 자바 SE API 사용 소송"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.hada.io/topic?id=4018"
  }, "구글 vs 오라클, 자바 SE API사용은 공정했다고 구글이 최종 승리")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://devblogs.microsoft.com/java/announcing-preview-of-microsoft-build-of-openjdk/"
  }, "Announcing Preview of Microsoft Build of OpenJDK")), "\n"), "\n", React.createElement(_components.h3, null, "MS 엣지 브라우저 사이드바 기능 추가"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.fastcompany.com/90613386/vertical-browser-tabs-microsoft-edge"
  }, "Microsoft Edge’s vertical tabs are the best reason yet to ditch Chrome")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://openart.tistory.com/2077"
  }, "한국 웹브라우저 점유율 2020년 12월 통계")), "\n"), "\n", React.createElement(_components.h3, null, "시피의 판교 부동산 이야기 : 성남시 트램 자체 비용으로 추진"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.yna.co.kr/view/AKR20210331153400061"
  }, "성남시, '판교 트램' 3천550억원 자체 재원으로 추진 - 연합뉴스")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
